module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-57746409-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"background":"#FFFFFF","background_secondary":"#EAEAEA","accent":"#2196F3","textPrimary":"#000000","textSecondary":"#7A7A7A","textBody":"#0C0C0C","translucent_accent":"rgba(33, 150, 243, 0.07)","shadow_bg":"rgba(234, 234, 234, 0.07)"},"dark":{"background":"#121212","background_secondary":"#151515","accent":"#64ffda","textPrimary":"#FFFFFF","textSecondary":"#858585","textBody":"#F3F3F3","translucent_accent":"rgba(100, 255, 218, 0.07)","shadow_bg":"rgba(21, 21, 21, 0.07)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
